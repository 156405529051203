@use '../common' as *;

@each $name, $spacing in $spacings {

  .width-#{$name} { width: $spacing;}
  .height-#{$name} { height: $spacing; }

}

.width-full { width: 100%; }
.width-three-quarters { width: 75%; }
.width-half { width: 50%; }
.width-quarter { width: 25%; }
.width-eighth { width: 12.5%; }
.width-tenth { width: 10%; }
.width-twentieth { width: 5%; }

.height-full { height: 100%; }
.height-half { height: 50%; }
.height-quarter { height: 25%; }

// Responsive must be below all defaults
.width-full { @include withResponsive() { width: 100%; }}
.width-three-quarters { @include withResponsive() { width: 75%; }}
.width-half { @include withResponsive() { width: 50%; }}
.width-quarter { @include withResponsive() { width: 25%; }}
.width-eighth { @include withResponsive() { width: 12.5%; }}
.width-tenth { @include withResponsive() { width: 10%; }}
.width-twentieth { @include withResponsive() { width: 5%; }}

.height-full { @include withResponsive() { height: 100%; }}
.height-half { @include withResponsive() { height: 50%; }}
.height-quarter { @include withResponsive() { height: 25%; }}
