@use '~@moved/ui/src/sondheim/common' as *;

.newPetCard {
  min-width: 250px;
  max-width: 312px;
  flex: 1 1 312px;
  min-height: 200px;
  border-radius: $radius-12;
  // layout children
  display: flex;
  flex-direction: column;
  gap: $sp-8;
  justify-content: center;
  align-items: center;
  position: relative;
  @include responsive(mobile) { width: 100%; }
  transition: border-color 0.2s;
  cursor: pointer;
  border: 1px solid $borderTertiary;
  &:hover {
    border-color: $borderAccentLight;
  }
  &.selected {
    border-color: $borderAccent;
  }
}

.radio {
  position: absolute;
  top: $sp-20;
  right: $sp-20;
  opacity: 0;
  transition: opacity 0.2s;
  .selected & {
    opacity: 1;
  }
  --color-1: #{$iconOnColor};
  --color-2: #{$iconSingleActive};
}

// .addPet {
//   @extend %base_card;
//   border: 1px solid $black-30;
//   background-color: rgba(255, 255, 255, 0.2);
//   cursor: pointer;
//   justify-content: center;
//   transition: background-color ease-out 0.2s, border-color ease-in-out 0.3s;

//   .add_button {
//     background-color: $black-20;
//     border-radius: 100px;
//     $dimensions: 44px;
//     height: $dimensions;
//     width: $dimensions;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-bottom: 8px;
//     transition: background-color ease-out 0.2s;
//   }

//   &:hover {
//     border: 1px solid $black-40;
//     background-color: rgba(255, 255, 255, 0.4);

//     .add_button {
//       background-color: $black-30;
//     }
//   }

//   &:active {
//     border: 1px solid $black-40;
//     background-color: rgba(255, 255, 255, 0.8);

//     .add_button {
//       background-color: $black-40;
//     }
//   }
// }
