@use '~@moved/ui/src/sondheim/common' as *;

.task {
  cursor: pointer;
  padding: $sp-4 0;
  justify-content: space-between;
  box-shadow: $shadow-1;
  transition: box-shadow 0.1s ease-out,
              padding 0.1s ease-out,
              margin 0.1s ease-out;
  &:hover {
    box-shadow: $shadow-3;
    padding: $sp-8 $sp-8;
    margin: -#{$sp-4} -#{$sp-8};
  }
}
.newTaskIndicator {
  position: absolute;
  top: 50%;
  left: $sp-8;
  width: $sp-8;
  height: $sp-8;
  margin-top: -#{$sp-4};
  border-radius: 50%;
  background-color: $contentAccent;
  @include responsive(mobile) {
    top: $sp-8;
  }
}
.taskTitle {
  align-items: center;
  position: relative;
  padding: $sp-16 0 $sp-16 $sp-24;
  flex: 1 1 auto;
  @include responsive(tablet) {
    flex: 1 1;
  }
  @include responsive(mobile) {
    padding: $sp-16 0 $sp-16 $sp-12;
    flex: 1 1 auto;
  }
}

.taskActions {
  flex: none;
  display: flex;
  align-items: stretch;
  @include responsive(mobile) { display: none; }
}
.taskActions > * {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
  &.visible {
    opacity: 1;
    visibility: visible;
  }
  .task:hover & {
    opacity: 1;
    visibility: visible;
  }
}
.actionButton {
  height: 100%;
  width: 150px;
  padding: $sp-12 $sp-16;
  background-color: $backgroundPrimary;
  justify-content: space-between;
  gap: $sp-8;
  span {
    color: $contentSecondary;
    transition: color 0.2s ease-out;
  }
  &:hover, .visible & {
    span { color: $contentPrimary; }
  }
}

.completeToggle {
  padding: $sp-12 0 $sp-12 $sp-24;
  flex: none;
  align-items: center;
  display: flex;
  .markComplete {
    opacity: 0;
    transition: opacity 0.2s ease-out;
    min-width: 150px;
    .task:hover & {
      opacity: 1;
    }
  }
  .isComplete {
    // we don't have a green button so we need to
    // specify the colors for all states
    background-color: $green-20;
    &:hover { background-color: $green-30; }
    &:active { background-color: $green-40; }
  }
  .isComplete {
    display: flex;
    min-width: 150px;
    .task:hover & {
      display: none;
    }
  }
  .markIncomplete {
    display: none;
    min-width: 150px;
    .task:hover & {
      display: flex;
    }
  }
  @include responsive(mobile) { display: none; }
}
.taskButtons {
  flex: none;
  padding: $sp-16 $sp-24;
  align-items: center;
  @include responsive(mobile) {
    padding: $sp-16 $sp-12;
  }
}
.taskButton {
  flex: 0 0 auto;
  color: $iconSingleEnabled;
  transition: color 0.2s ease-out;
  &:hover {
    color: $iconSingleHover;
  }
}

.taskArrow {
  .task:hover & {
    color: $iconSingleActive;
  }
}

.delete {
  color: $contentError;
  svg {
    --color-1: #{$iconMultiError};
    --color-2: #{$iconMultiError};
  }
}


.dragHandle {
  // on mobile no hover state exists so we always show the dragHandle
  @include responsive(tablet,desktop) {
    display: none;
    .task:hover &, .task.dragging & {
      display: block;
    }
  }
  svg {
    --color-1: #{$iconSingleEnabled};
  }
  &:hover, .task.dragging & {
    svg {
      --color-1: #{$iconSingleHover};
    }
  }
}
.icon {
  // on mobile no hover state exists so we always show the dragHandle
  @include responsive(mobile) {
    display: none;
  }
  @include responsive(tablet,desktop) {
    display: block;
    .task:hover &, .task.dragging & {
      display: none;
    }
  }
}
