@use '~@moved/ui/src/sondheim/common' as *;

.petCard {
  min-width: 250px;
  max-width: 312px;
  // width: 312px;
  // height: 400px;
  flex: 1 1 312px;
  // override the card styles
  padding: $sp-16 $sp-8 $sp-8;
  border-radius: $radius-12;
  border: 1px solid transparent;
  // layout children
  display: flex;
  flex-direction: column;
  gap: $sp-24;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @include responsive(mobile) { width: 100%; }
  transition: border 0.2s, box-shadow 0.2s;
  &:hover.clickable {
    cursor: pointer;
    box-shadow: $shadow-3;
  }
  &.selected {
    border: 1px solid $borderAccent;
  }
}

.radio {
  position: absolute;
  top: $sp-20;
  right: $sp-20;
  opacity: 0;
  transition: opacity 0.2s;
  .selected & {
    opacity: 1;
  }
  --color-1: #{$iconOnColor};
  --color-2: #{$iconSingleActive};
}

.circle {
  height: $sp-28;
  width: $sp-28;
  border-radius: $radius-full;
  background-color: $backgroundPrimary;
  flex: none;
  box-shadow: inset $shadow-2;
}

.ineligible {
  cursor: pointer;
  svg { --color-1: #{$iconMultiErrorLight} !important; }
}

.petImage {
  background-color: $backgroundTertiary;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-position: center center;
  border-radius: $radius-4;
  position: relative;
}

.petIcon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.65);
  & > svg {
    max-width: 50%;
  }
}
